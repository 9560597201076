<template>
    <div
        :class="`modal-status ${modal.display ? 'active' : 'disabled'} ${
            modal.status ? 'variant-' + modal.status : ''
        }`"
    >
        <div class="d-flex">
            <div class="status">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="modal.status === 'success'"
                >
                    <path
                        d="M7.99998 14.6663C11.6819 14.6663 14.6666 11.6816 14.6666 7.99967C14.6666 6.97014 14.4333 5.99512 14.0165 5.12461C13.8763 4.83161 13.4913 4.78454 13.2616 5.01424L8.27612 9.99967C7.75542 10.5204 6.9112 10.5204 6.3905 9.99968L4.52858 8.13775C4.26823 7.8774 4.26823 7.45529 4.52858 7.19494C4.78892 6.93459 5.21103 6.93459 5.47138 7.19494L7.33331 9.05687L12.5678 3.8261C12.7574 3.63658 12.7651 3.32962 12.5699 3.14578C11.3766 2.0218 9.76871 1.33301 7.99998 1.33301C4.31808 1.33301 1.33331 4.31778 1.33331 7.99967C1.33331 11.6816 4.31808 14.6663 7.99998 14.6663Z"
                        fill="#83BF6E"
                    />
                </svg>
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-else-if="modal.status === 'error'"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99998 14.6663C11.6819 14.6663 14.6666 11.6816 14.6666 7.99967C14.6666 4.31778 11.6819 1.33301 7.99998 1.33301C4.31808 1.33301 1.33331 4.31778 1.33331 7.99967C1.33331 11.6816 4.31808 14.6663 7.99998 14.6663ZM5.52858 10.4711C5.26823 10.2107 5.26823 9.78862 5.52858 9.52827L7.05719 7.99965L5.52864 6.4711C5.26829 6.21075 5.26829 5.78864 5.52864 5.52829C5.78899 5.26794 6.2111 5.26794 6.47145 5.52829L8 7.05685L9.52858 5.52827C9.78893 5.26792 10.211 5.26792 10.4714 5.52827C10.7317 5.78862 10.7317 6.21073 10.4714 6.47108L8.94281 7.99966L10.4714 9.5283C10.7318 9.78865 10.7318 10.2108 10.4714 10.4711C10.2111 10.7315 9.78899 10.7315 9.52864 10.4711L8 8.94246L6.47138 10.4711C6.21103 10.7314 5.78892 10.7314 5.52858 10.4711Z"
                        fill="#FF6A55"
                    />
                </svg>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.6666 7.99967C14.6666 11.6816 11.6819 14.6663 7.99998 14.6663C4.31808 14.6663 1.33331 11.6816 1.33331 7.99967C1.33331 4.31778 4.31808 1.33301 7.99998 1.33301C11.6819 1.33301 14.6666 4.31778 14.6666 7.99967ZM7.99998 7.33301C8.36817 7.33301 8.66665 7.63148 8.66665 7.99967V11.3336C8.66665 11.7018 8.36817 12.0003 7.99998 12.0003C7.63179 12.0003 7.33331 11.7018 7.33331 11.3336V7.99967C7.33331 7.63148 7.63179 7.33301 7.99998 7.33301ZM7.99998 5.99967C8.36817 5.99967 8.66665 5.7012 8.66665 5.33301C8.66665 4.96482 8.36817 4.66634 7.99998 4.66634C7.63179 4.66634 7.33331 4.96482 7.33331 5.33301C7.33331 5.7012 7.63179 5.99967 7.99998 5.99967Z"
                        fill="#800020"
                    />
                </svg>
            </div>
            <div class="info">
                <div class="title">{{ modal.title }}</div>
                <div class="body">{{ modal.body }}</div>
            </div>
            <div class="close">
                <button aria-label="close modal" @click="$store.commit('hideModal')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.47142 3.52827C4.21107 3.26792 3.78896 3.26792 3.52861 3.52827C3.26826 3.78862 3.26826 4.21073 3.52861 4.47108L7.05717 7.99965L3.52854 11.5283C3.2682 11.7886 3.2682 12.2107 3.52854 12.4711C3.78889 12.7314 4.211 12.7314 4.47135 12.4711L7.99998 8.94245L11.5286 12.4711C11.789 12.7314 12.2111 12.7314 12.4714 12.4711C12.7318 12.2107 12.7318 11.7886 12.4714 11.5283L8.94279 7.99965L12.4714 4.47108C12.7317 4.21073 12.7317 3.78862 12.4714 3.52827C12.211 3.26792 11.7889 3.26792 11.5285 3.52827L7.99998 7.05684L4.47142 3.52827Z"
                            fill="#6F767E"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            modal: (state) => state.app.modal,
        }),
    },
}
</script>

<style lang="scss">
.modal-status {
    position: fixed;
    z-index: 1000;
    top: 48px;
    padding: 8px;
    transition: 0.4s ease-in;
    background: #272b30;
    box-shadow:
        0px 2px 4px 0px rgba(0, 0, 0, 0.1),
        0px 0px 1px 0px #000 inset;
    border-radius: 8px;
    > .d-flex {
        gap: 8px;
        .close > button {
            background: none;
        }
        .info {
            font: {
                family: 'Inter', sans-serif;
            }

            .title {
                color: #f4f4f4;
                font: {
                    size: 14px;
                    weight: 600;
                }
            }

            .body {
                margin-top: 4px;
                color: #c2c7cc;
                font: {
                    size: 14px;
                }
            }
        }

        .status {
            margin-top: 1px;
        }
    }

    &.variant-error {
        .info {
            .body {
                color: #ff6a55;
            }
        }
    }

    &.active {
        right: 48px;
        transform: translateX(0%);
    }
    &.disabled {
        right: 0;
        transform: translateX(100%);
    }
}
</style>
